import { ChapterSetting, ChapterSettingInput } from '@/api/types'
import endpoints from '@/constants/endpoints'
import axios from 'axios'

/**
 * @name 取得章節設定
 * @link https://api.dev.kadokado.me/v2/swagger/index.html#/Creator/get_creator_chapters__chapterId_
 */
export const getChapterSettingById = async (chapterId: number): Promise<ChapterSetting> => {
  const res = await axios({
    method: 'GET',
    url: `${endpoints.CHAPTER}/${chapterId}`,
  })
  return res.data
}

/**
 * @name 更新章節設定
 * @link https://api.dev.kadokado.me/v2/swagger/index.html#/Creator/put_creator_chapters__chapterId_
 */

export const updateChapterSettingById = async (data: ChapterSettingInput): Promise<unknown> => {
  const { id, ...rest } = data
  const res = await axios({
    method: 'PUT',
    url: `${endpoints.CHAPTER}/${id}`,
    data: rest,
  })
  return res.data
}

/**
 * @name 更新章節顯示名稱
 * @link https://api.dev.kadokado.me/v2/swagger/index.html#/Creator/put_creator_chapters__chapterId__name
 */
export const updateChapterDisplayNameById = async (params: {
  chapterId: number
  displayName: string
}): Promise<unknown> => {
  const { chapterId, displayName } = params
  const res = await axios({
    method: 'PUT',
    url: `${endpoints.CHAPTER}/${chapterId}/name`,
    data: { displayName },
  })
  return res.data
}
