export enum ListingStatus {
  // 已發布
  LISTED = 'listed',
  // 已排程
  SCHEDULED = 'scheduled',
  // 未發布且未排程
  UNLISTED = 'unlisted',
}

export interface Chapter {
  id: number
  titleId: number
  collectionId: number
  sequenceNum: number
  displayName: string
  content: string | null
  isFree: boolean
  points: number | null
  rental: number | null
  wordCount: number
  readCount: number
  allowReadingTicket: boolean
  listingStatus: ListingStatus
  listingFrom: string | null
}

export type ChapterSetting = Pick<
  Chapter,
  | 'id'
  | 'displayName'
  | 'isFree'
  | 'points'
  | 'rental'
  | 'titleId'
  | 'listingStatus'
  | 'listingFrom'
>

export type ChapterSettingInput = Pick<
  Chapter,
  'id' | 'displayName' | 'points' | 'rental' | 'listingFrom'
>
