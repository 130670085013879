import { ListingStatus } from '@/api/types'
import dayjs from 'dayjs'

export const getTitleListingStatus = (
  visible?: boolean,
  listingFrom?: string
): ListingStatus | undefined => {
  if (visible === false) {
    return ListingStatus.UNLISTED
  } else if (!!visible && dayjs().isAfter(listingFrom)) {
    return ListingStatus.LISTED
  } else if (!!visible && dayjs().isBefore(listingFrom)) {
    return ListingStatus.SCHEDULED
  }

  return undefined
}
