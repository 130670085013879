/** @jsxImportSource theme-ui */
import { MemberKind } from '@/api/types'
import ImageWithFallback from '@/components/ImageWithFallback'
import { MEMBER_KIND_CONFIG } from '@/constants/memberKind'
import { pxToRem } from '@/utils'
import { ImageProps } from 'next/image'
import Link from 'next/link'
import { Fragment, memo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Box, ThemeUIStyleObject } from 'theme-ui'

interface Props {
  avatarUrl?: string | null
  size?: 'small' | 'large' | 'xLarge' | 'xxLarge'
  linkHref?: string
  isLoading?: boolean
  alt?: string
  memberKind?: MemberKind
  avatarTarget?: '_blank' | '_self'
  extraImageProps?: ImageProps | {}
  extraStyles?: ThemeUIStyleObject
}

// 大頭照尺寸
const AVATAR_SIZES = {
  small: pxToRem(40),
  large: pxToRem(80),
  xLarge: pxToRem(128),
  xxLarge: pxToRem(256),
}

// 預設大頭照
const DEFAULT_AVATAR = '/static/images/Kadokado-member_40.svg'

/**
 * @name 頭像元件
 * @param props.avatarUrl 頭像網址
 * @param props.size
 * @param props.linkHref 如果有傳入連結，元件就會包覆在Link物件中被渲染
 * @param props.isLoading 是否在loading狀態
 * @param props.alt 圖片的alt
 * @param props.memberKind 認證作家身份
 * @param props.extraImageProps 欲傳遞給 ImageWithFallback props
 * @param props.extraStyles 客製化樣式
 */
const Avatar = ({
  avatarUrl,
  size = 'small',
  linkHref,
  isLoading = false,
  alt = '大頭照',
  memberKind = MemberKind.Reader,
  avatarTarget = '_self',
  extraImageProps = {},
  extraStyles = {},
}: Props) => {
  const src = avatarUrl || DEFAULT_AVATAR

  const avatarStyle = {
    borderStyle: 'solid',
    borderWidth: size === 'small' ? pxToRem(1) : pxToRem(2),
    borderColor: MEMBER_KIND_CONFIG[memberKind].color,
    backgroundColor: MEMBER_KIND_CONFIG[memberKind].color,
  }

  const renderContent = () => {
    return (
      <Box
        sx={{
          position: 'relative',
          borderRadius: 'round',
          overflow: 'hidden',
          width: AVATAR_SIZES[size],
          height: AVATAR_SIZES[size],
          ...(memberKind !== MemberKind.Reader ? avatarStyle : {}),
          ...extraStyles,
        }}
      >
        <ImageWithFallback
          {...extraImageProps}
          src={src}
          alt={alt}
          fallbackSrc={DEFAULT_AVATAR}
          fill
          sx={{
            objectFit: 'cover',
          }}
          sizes={AVATAR_SIZES[size]}
        />
      </Box>
    )
  }

  if (isLoading) return <Skeleton circle width={AVATAR_SIZES[size]} height={AVATAR_SIZES[size]} />

  if (!!linkHref) {
    return (
      <Link href={linkHref} target={avatarTarget}>
        {renderContent()}
      </Link>
    )
  }

  return <Fragment>{renderContent()}</Fragment>
}

export default memo(Avatar)
