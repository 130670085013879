import { IErrorResponse } from './../types/index'
import { ComponentType } from 'react'

export * from './styles'
export * from './format'
export * from './uniqueId'
export * from './time'
export * from './kadokado'
export * from './image'

export const IS_PROD_EXEC_ENV = process.env.NODE_ENV === 'production'
export const IS_CLIENT_SIDE = typeof window !== 'undefined'

/**
 * @name KadoKado 前台 domain
 * @description
 * 因應大陸市場需求，動態產生導向前台的 domain
 */
export const getKadoDomain = () => {
  let domain = process.env.NEXT_PUBLIC_KADOKADO_URL

  if (IS_CLIENT_SIDE && IS_PROD_EXEC_ENV) {
    const { origin } = window.location
    domain =
      origin !== process.env.NEXT_PUBLIC_BASE_URL
        ? origin.replace('creator', 'www')
        : process.env.NEXT_PUBLIC_KADOKADO_URL
  }

  return domain
}

/**
 * @name Account domain
 * @description
 * 因應大陸市場需求，動態產生導向 Account 的 domain
 */
export const getAccountDomain = () => {
  let domain = process.env.NEXT_PUBLIC_ACCOUNT_URL

  if (IS_CLIENT_SIDE && IS_PROD_EXEC_ENV) {
    const { origin } = window.location
    domain =
      origin !== process.env.NEXT_PUBLIC_BASE_URL
        ? origin.replace('creator', 'account')
        : process.env.NEXT_PUBLIC_ACCOUNT_URL
  }

  return domain
}

/**
 * @name 取得放在storage圖片的完整url
 * @param src API回傳之相對路徑
 * @description
 * 將傳入的相對路徑encode，並返回完整的url
 */
export const getStorageImage = (src: string) => {
  return `${process.env.NEXT_PUBLIC_STORAGE_URL}/${encodeURIComponent(src)}`
}

/**
 * @name 取得Component之displayName
 * @param Component
 * @description
 * 取得元件的顯示名稱，用在dev tool顯示上
 */
export function getDisplayName<T>(Component: ComponentType<T>) {
  return Component.displayName || Component.name || 'Component'
}

/**
 * @name 從Error取出需要的Response
 */
export const getErrorResponse = (error: any): IErrorResponse | undefined => {
  if (process.env.NODE_ENV !== 'production') console.log(JSON.stringify(error, undefined, 2))

  return error?.response?.errors?.[0]?.extensions?.response
}

/**
 * @name 將錯誤訊息轉換成人讀的訊息
 * @param 將 IErrorResponse 中的 error傳入
 * @description
 * 這個函式
 */
export const convertErrorMessage = (error: string, defaultMessage: string) => {
  switch (error) {
    case 'Forbidden':
      return '登入憑證失效'

    case 'DUPLICATED_TITLE_DISPLAY_NAME_AND_OWNER_KEY':
      return '作品名稱重複'

    default:
      return defaultMessage
  }
}

/**
 * @name 導向至Account
 * @param goTo Account上的頁面(pathname), ex: /signin, /signup
 * @param returnedTo 在Account完成動作後，導回哪個頁面？
 * @param method 用什麼方式執行這個導向
 */
export const goToAccount = (
  goTo: string,
  returnedTo: string,
  method: 'replace' | 'push' | 'new' = 'replace'
) => {
  // 因應大陸市場需求，動態產生導向Account的網址
  const creatorDomain =
    IS_CLIENT_SIDE && IS_PROD_EXEC_ENV ? window.location.origin : process.env.NEXT_PUBLIC_BASE_URL

  const url = `${getAccountDomain()}${goTo}` + `?redirectUri=${creatorDomain}${returnedTo}`

  switch (method) {
    case 'push':
      return (location.href = url)
    case 'new':
      return window.open(url)
    default:
      return location.replace(url)
  }
}

/**
 * @name 深拷貝物件
 */
export const deepCopy = <T>(object: T): T => {
  return JSON.parse(JSON.stringify(object))
}

/**
 * @name 取得隨機Elements
 * @param arr 原始陣列
 * @param targetLength 想要取得的數量
 * @description
 * 從原始的陣列中，隨機取出想要的數量，組成新的陣列返回
 */
export const getRandomElements = <T>(arr: T[] = [], targetLength: number): T[] => {
  // 如果原始陣列的長度小於等於目標數量，直接返回
  if (arr.length <= targetLength) return [...arr]

  const output = []
  const copiedArr = [...arr]

  while (output.length < targetLength) {
    const randomIndex = Math.floor(Math.random() * copiedArr.length)
    const pickedElement = copiedArr.splice(randomIndex, 1)[0]
    output.push(pickedElement)
  }

  return output
}
