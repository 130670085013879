import { IS_CLIENT_SIDE, IS_PROD_EXEC_ENV } from '@/utils'

/**
 * @name 轉換為相對路徑
 * @param url 檔案路徑
 * @description
 * 如果傳入的路徑是絕對路徑就轉換為相對路徑
 * 情境一：
 * url 為 https://storage.googleapis.com/kadokado-storage-dev/avatar/22937/RCJJJGjAmm0pGij2/download.jpeg 時，
 * 移除 domain 與 buket-name 這層然後回傳 avatar/22937/RCJJJGjAmm0pGij2/download.jpeg
 * 情境二：
 * url 為 https://kadokado-storage.dev.kadokado.me/ownerId/22928/cover/4c115bde7cef8cde656905b9e07e45b8/blob 時，
 * 移除 domain 然後回傳 ownerId/22928/cover/4c115bde7cef8cde656905b9e07e45b8/blob
 * 情境三：
 * url 為 ownerId/22928/cover/4c115bde7cef8cde656905b9e07e45b8/blob 時，就直接回傳
 */
const convertToRelativePath = (url: string) => {
  if (url.startsWith('https://')) {
    const originUrl = new URL(url)
    const pathname = originUrl.pathname

    // TODO 後端移除來自`storage.googleapis.com`的圖片時可以拔掉
    if (originUrl.origin === 'https://storage.googleapis.com') {
      const pathnameWithoutBucketName = pathname.replace(/\/[^/]*\//, '')
      return pathnameWithoutBucketName
    }

    const pathnameWithoutPrefixSlash = pathname.replace('/', '')
    return pathnameWithoutPrefixSlash
  }

  return url
}

/**
 * @name 從imgproxy取得圖片
 * @param src 圖片路徑
 * @param width 圖片寬度預設為 0，0 就是不縮放回傳原尺寸
 * @description
 * 1. 目前圖片格式如果是從 storage 直接取圖的，都必須經過 imgproxy 來取，詳情可參考 https://kadokado.atlassian.net/browse/KAD-2079
 * 2. 後端會將圖片格式統一成 https://img.dev.kadokado.me/insecure/s:0/plain/pathToPicture.jpeg，前端只需要將 s:0 替換成我們想要的圖片寬度（s:0 為原圖）
 * 3. 由於 v1 api 要處理成 2. 的格式較複雜，所以需要把 imgproxy 的 domain 做動態處理 https://kadokado.atlassian.net/browse/KAD-2971
 * @link https://docs.imgproxy.net/generating_the_url?id=processing-options
 */
export const getImgProxyImage = ({ src, width = 0 }: { src: string; width?: number }) => {
  console.log('getImgProxyImage', src, width)
  if (src.includes('/s:0')) {
    return src.replace('/s:0', `/s:${width}`)
  }

  const options = `/insecure/s:${width}/plain/${convertToRelativePath(src)}`
  let url = process.env.NEXT_PUBLIC_IMAGE_RESIZING_SERVER_URL

  if (IS_CLIENT_SIDE && IS_PROD_EXEC_ENV) {
    const { origin } = window.location

    if (origin !== process.env.NEXT_PUBLIC_BASE_URL) {
      url = origin.replace('creator', 'img')
    }
  }

  return url + options
}
