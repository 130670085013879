import { getImgProxyImage } from '@/utils'
import Image, { ImageProps } from 'next/image'
import React, { memo, useCallback, useEffect, useState } from 'react'

interface Props extends ImageProps {
  fallbackSrc?: string
  src: string
  alt: string
}

const ImageWithFallback = ({ src, alt, fallbackSrc, ...rest }: Props) => {
  const [imgSrc, setImgSrc] = useState(src)

  const handleFallbackSrc = useCallback(() => {
    if (fallbackSrc) setImgSrc(fallbackSrc)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isStaticSrc = imgSrc.includes('/static/images')

  useEffect(() => {
    setImgSrc(src)
  }, [src])

  return (
    <Image
      {...rest}
      loader={isStaticSrc ? undefined : getImgProxyImage}
      src={imgSrc}
      alt={alt}
      onError={handleFallbackSrc}
    />
  )
}

export default memo(ImageWithFallback)
