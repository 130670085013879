import { UpdateTitleInput, TitleStats, TitleInformation } from '@/api/types'
import endpoints from '@/constants/endpoints'
import axios from 'axios'

/**
 * @name 作品列表
 * @link https://api.dev.kadokado.me/v2/swagger/index.html#/Creator%20Titles/get_v2_creator_myTitleStats
 */
export const getMyTitleStats = async (): Promise<TitleStats[]> => {
  const res = await axios({
    method: 'GET',
    url: endpoints.MY_TITLE_STATS,
  })
  return res.data
}

/**
 * @name 新增作品
 * @link https://api.dev.kadokado.me/v2/swagger/index.html#/Creator%20Titles/post_v2_creator_titles
 */
export const createTitle = async (data: {
  displayName: string
  genreIds: number[]
}): Promise<{ titleId: number }> => {
  const res = await axios({
    method: 'POST',
    url: endpoints.TITLE,
    data,
  })
  return res.data
}

/**
 * @name 取得作品
 * @link https://api.dev.kadokado.me/v2/swagger/index.html#/Creator%20Titles/get_v2_creator_titles__titleId_
 */
export const getTitleById = async (titleId: number): Promise<TitleInformation> => {
  const res = await axios({
    method: 'GET',
    url: `${endpoints.TITLE}/${titleId}`,
  })
  return res.data
}

/**
 * @name 更新作品
 * @link https://api.dev.kadokado.me/v2/swagger/index.html#/Creator%20Titles/put_v2_creator_titles__titleId_
 */
export const updateTitleById = async (data: UpdateTitleInput): Promise<unknown> => {
  const { titleId, ...rest } = data
  const res = await axios({
    method: 'PUT',
    url: `${endpoints.TITLE}/${titleId}`,
    data: rest,
  })
  return res.data
}

/**
 * @name 刪除作品
 * @link https://api.dev.kadokado.me/v2/swagger/index.html#/Creator%20Titles/delete_v2_creator_titles__titleId_
 */
export const deleteTitleById = async (titleId: number): Promise<unknown> => {
  const res = await axios({
    method: 'DELETE',
    url: `${endpoints.TITLE}/${titleId}`,
  })
  return res.data
}

/**
 * @name 發布作品
 */
export const publishTitleById = async (params: {
  titleId: number
  listingFrom: string
}): Promise<unknown> => {
  const { titleId, listingFrom } = params
  const res = await axios({
    method: 'POST',
    url: `${endpoints.TITLE}/${titleId}/publish`,
    data: { listingFrom },
  })
  return res.data
}

/**
 * @name 取消發佈作品
 */
export const unpublishTitleById = async (titleId: number): Promise<unknown> => {
  const res = await axios({
    method: 'POST',
    url: `${endpoints.TITLE}/${titleId}/unpublish`,
  })
  return res.data
}

/**
 * @name 上傳作品封面
 * @link https://api.dev.kadokado.me/v2/swagger/index.html#/Creator/post_v2_creator_titles__titleId__cover
 */
export const uploadTitleCoverById = async (params: {
  titleId: number
  coverData: FormData
}): Promise<{
  coverUrl: string
  path: string
}> => {
  const { titleId, coverData } = params
  const res = await axios({
    method: 'POST',
    url: `${endpoints.TITLE}/${titleId}/cover`,
    headers: { 'Content-Type': 'multipart/form-data' },
    data: coverData,
  })
  return res.data
}

/**
 * @name 同步作品資料(Elasticsearch)
 * @link https://api.dev.kadokado.me/v2/swagger/index.html#/Title/post_v2_creator_titles__titleId_sync
 * @description
 * 通知 Elasticsearch 更新作品資料，graphql chapter 相關 APIs 改成 v2 後，不再需要呼叫此 API
 * 相關 APIs 可參考：https://kadokado.atlassian.net/browse/KAD-4933
 */
export const syncTitleToElasticsearchById = async (titleId: number): Promise<unknown> => {
  const res = await axios({
    method: 'POST',
    url: `${endpoints.TITLE}/${titleId}/sync`,
  })
  return res.data
}
